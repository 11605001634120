import React, { useState, useEffect } from "react";
import BaseApi from "../api/BaseApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/styles/register.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { isValidPhoneNumber } from "react-phone-number-input/input";
import PhoneInput from "react-phone-number-input/input";
import bidLogoPurple from "../assets/images/BiD-LOQO-purple.svg";

import { options } from "../constants";
import { useSelector } from "react-redux";

export default function SimpleSliderFleet() {
  const language = useSelector(state => state.language)
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows:false,
  };

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState();
  const [carCount, setCarCount] = useState();
  const [isValidNumber, setIsValidNumber] = useState(true);
  const [isValidName, setIsValidName] = useState(true);
  const [isValidSurname, setIsValidSurname] = useState(true);
  const [isValidCarCount, setIsValidCarCount] = useState(true);
  useEffect(() => {
    setIsValidNumber(true);
  }, [phone]);

  const validatePhoneNumber = (phone) => {
    if (phone !== undefined) {
      setIsValidNumber(isValidPhoneNumber(phone));
      if (isValidPhoneNumber(phone)) {
        return true;
      }
    } else {
      setIsValidNumber(false);
    }
  };

  const validateNameSurname = (name, surname) => {
    if ((name.length <= 2) | (name.length >= 20)) {
      setIsValidName(false);
      if ((surname.length <= 2) | (surname.length >= 20)) {
        setIsValidSurname(false);
      } else {
        setIsValidSurname(true);
        return true;
      }
    } else {
      setIsValidName(true);
      if ((surname.length <= 2) | (surname.length >= 20)) {
        setIsValidSurname(false);
      } else {
        setIsValidSurname(true);
        return true;
      }
    }
  };
  const validateCarCount = () => {
    if (!carCount) {
      setIsValidCarCount(false);
      return false;
    }
    return true;
  };

  const request = async () => {
    const fd = new FormData();
    fd.append("name", name);
    fd.append("lastname", surname);
    fd.append("mobileNum", phone);
    fd.append("car", carCount);
    const response = await BaseApi.post("/registerAsFleet", fd);
    if (response.data.statusCode === 200) {
      toast.success("Qeydiyyatınız uğurla nəticələndi", { autoClose: 2000 });
    } else {
      toast.error(response.data.message);
    }
  };

  const HandleChange = (e, num) => {
    switch (num) {
      case 1:
        setName(e.target.value);
        setIsValidName(true);
        break;
      case 3:
        setIsValidNumber(true);
        break;
      case 5:
        setSurname(e.target.value);
        setIsValidSurname(true);
        break;
      case 10:
        e.target.value >= 0 &&
          e.target.value <= 100 &&
          setCarCount(
            e.target.value
              ?.replace("-", "")
              ?.replace("+", "")
              ?.replace("--", "")
              ?.replace("++", "")
          );
        setIsValidCarCount(true);
        break;
      default:
        break;
    }
  };

  const HandleSubmit = () => {
    let phoneNumberValid = validatePhoneNumber(phone);
    let nameSurnameValid = validateNameSurname(name, surname);
    let carCountValid = validateCarCount(carCount);
    if (phoneNumberValid && nameSurnameValid && carCountValid) {
      request();
    }
  };

  return (
    <>
      <ToastContainer />

      <Slider {...settings}>
        <div className="register-right-container 1">
          <div className="register-right-top">
            <div className="register-right-text-container">
              <h1
                className="register-heading"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={bidLogoPurple}
                  alt="bid-logo"
                  className="bid-right-logo"
                />
                <span
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginLeft: "10px",
                    marginTop: "10px",
                    color: "#6F52EA",
                    fontWeight: "700",
                  }}
                >
                  {options[language].owner}
                </span>
              </h1>
              <p className="welcome"> {options[language].welcome}</p>
            </div>
            <label htmlFor="file-input" className="profile-photo"></label>
          </div>
          <form
            className="form-register"
            onSubmit={(e) => {
              e.preventDefault();
              HandleSubmit();
            }}
          >
            <div className="form-register-inputs">
              <div className="register-field-two-inputs">
                <div className="register-field name">
                  <label htmlFor="name">{options[language].nameReq}</label>
                  <input
                    placeholder="Ad"
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => HandleChange(e, 1)}
                  />
                  {!isValidName ? (
                    <div className="error-message">
                      {options[language].nameLimits}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="register-field surname">
                  <label htmlFor="surname">{options[language].surnameReq}</label>
                  <input
                    placeholder="Soyad"
                    type="text"
                    id="surname"
                    value={surname}
                    onChange={(e) => HandleChange(e, 5)}
                  />
                  {!isValidSurname ? (
                    <div className="error-message">
                      {options[language].surnameLimits}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="register-field phone">
                <label htmlFor="phone">{options[language].mobileNum}</label>
                <PhoneInput
                  className="phone-input"
                  id="phone"
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="AZ"
                  value={phone}
                  onChange={setPhone}
                />
                <span className="country-code">+994</span>
              </div>
              {!isValidNumber ? (
                <div className="error-message">{options[language].correctMobileNum}</div>
              ) : (
                <></>
              )}
              <div className="register-field phone">
                <label htmlFor="phone">
                {options[language].autoCounts}
                </label>
                <input
                  placeholder="Avtomobil sayı"
                  type="number"
                  id="carCount"
                  value={carCount
                    ?.replace("-", "")
                    ?.replace("+", "")
                    ?.replace("--", "")
                    ?.replace("++", "")}
                  min={0}
                  max={100}
                  onChange={(e) => HandleChange(e, 10)}
                />
              </div>
              {!isValidCarCount ? (
                <div className="error-message">{options[language].autoCountsReq}</div>
              ) : (
                <></>
              )}
            </div>
            <div
              className="register-buttons"
              style={{
                bottom: "0",
              }}
            >
              <button className="create-acc" type="submit">
              {options[language].howItsWorksSteps[0]}
              </button>
            </div>
          </form>
        </div>
      </Slider>
    </>
  );
}
