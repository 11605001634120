import React, { useState } from 'react'
import '../assets/styles/why.css'
import bidPurple from '../assets/images/BiD-LOQO-purple.svg'
import card1 from '../assets/images/Pul-ikonu.svg'
import card1W from '../assets/images/PUL-iKON-Ag.svg'

import card2 from '../assets/images/fellow-icon.svg'
import card2W from '../assets/images/fellow-icon-ag.svg'

import card3 from '../assets/images/info-icon.svg'
import card3W from '../assets/images/info-icon-ag.svg'

import card4 from '../assets/images/TiP.svg'
import card4W from '../assets/images/TiP.svg'
import { options } from '../constants'
import { useSelector } from 'react-redux'

function WhyBid (props) {
  const [card, setCard] = useState([false, false, false, false])
  const language = useSelector(state => state.language)
  return (
    <div id='why' className='Why-Bid'>
      <h3 className='why-heading'>
        <p>{options[language].why}{" "} </p>
        <p style={{ color: '#6F52EA' }}>
          <span style={{ fontFamily: 'Comfortaa', margin: '0 5px' }}>
            bid
          </span>{" "}
          {" "}{options[language].select}
        </p>
      </h3>
      <p className='why-small-text'>
        {options[language].thinkYourBudget}
      </p>

      <div className="cards-container">
        <div
          className='card'
          onMouseEnter={() => setCard([false, true, false, false])}
          onMouseLeave={() => setCard([false, false, false, false])}
        >
          <div className='card-inner-container'>
            <img
              src={
                window.innerWidth < 670
                  ? card2
                  : window.innerWidth > 670 && card[1]
                    ? card2
                    : card2
              }
              alt=''
              className='card-logo'
            />
            <div className='hr-line'></div>
            <h2 className='card-header'>{options[language].travelWithOptimal}</h2>
            <p className='card-content'>
              {options[language].travelWith2}
            </p>
          </div>
        </div>
        <div
          className='card'
          onMouseEnter={() => setCard([false, false, true, false])}
          onMouseLeave={() => setCard([false, false, false, false])}
        >
          <div className='card-inner-container'>
            <img
              src={
                window.innerWidth < 670
                  ? card1W
                  : window.innerWidth > 670 && card[2]
                    ? card1W
                    : card1
              }
              alt=''
              className='card-logo'
            />
            <div className='hr-line'></div>
            <h2 className='card-header'>{options[language].withoutCommisions}</h2>
            <p className='card-content'>
            {options[language].centsFromBalance}
            </p>
          </div>
        </div>
        <div
          className="card"
          onMouseEnter={() => setCard([false, false, false, true])}
          onMouseLeave={() => setCard([false, false, false, false])}
        >
          <div className='card-inner-container'>
            <img
              src={window.innerWidth > 670 && card[3] ? card3W : card3}
              alt=''
              className='card-logo'
            />
            <div className='hr-line'></div>

            <h2 className='card-header'>
              {options[language].infoAboutTr}
            </h2>
            <p className='card-content'>
              
              {options[language].infoAboutTr2}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyBid
