export const options = {
  az: {
    about: "Haqqımızda",
    aboutDesc1: "Biz etdiyimiz her şeyə peşəkarlıq gətiririk.",
    aboutDesc2:
      '"Bid Taxi" mobil tətbiqi Azərbaycan mütəxəssisləri tərəfindən yaradılmış, müəllif hüquqları Azərbaycan Respublikası Əqli Mülkiyyət Agentliyində qeydiyyata alınaraq müvafiq qanunvericiliklə qorunan komputer proqramıdır. Müəllifliklə əlaqəli bütün hüquqlar "Bid Taxi" MMC-yə məxsusdur. Əsas məqsədi taxsi xidmətinin mobil tətbiq üzərindən təşkili olan bu layihəyə Azərbaycan Respublikasının kiçik və orta biznesin İnkişafı Agentliyi tərəfindən Startap Şəhadətnaməsi, AYDA tərəfindən isə 31.05.2024-cü il tarixli TSO № 10012 taksi sifarişi operatoru fəaliyyətinə icazə verilmişdir. "Bid Taxi" mobil tətbiqi sərnişinlərlə taksi xidməti göstərən şəxslərin ən sürətli şəkildə əlaqə yaratmalarını, sifarişlərin qəbulunu və hər iki tərəfin qəbul etdiyi əlverişli şərtlərlə taxsi xidmətinin reallaşmasını təmin edir. Qayəmiz hər kəsə ədalətli qiymətlə komfortlu taxsi xidmətinin əlçatımlı olması imkanına nail olmaqdır.',
    autoTypesName: "Nəqliyyat vasitələrimiz",
    autoTypes: ["Ekonom", "Biznes", "XL"],
    rider: "Yolçu",
    requared: "- də gərək!",
    driver: "Sürücü",
    rider: "Sərnişin",
    lowComisions: "Daha az komissiya,",
    moreReve: "Daha çox qazanc!",
    forEveryRide: "Hər gedişə sadəcə",
    comision: "komissiya",
    payComision: "komissiya ödəyin.",
    addiR: "ə qədər aylıq əlavə qazanc əldə edin!",
    forAzn: "AZN-ə",
    additionalReven: "qədər əlavə qazanc",
    bidDriver: "Bid: Sürücü",
    bidRider: "Bid: Sərnişin",
    faqQuestions: "Tez-tez soruşulan suallar",
    faqDesc1:
      "Bu bölmədə bid istifadəçilərinin mütəmadi olaraq göndərdiyi sorğuları görə bilərsiniz.",
    downloadMOre: "Daha çox yüklə",
    whyBid: "Nə üçün bid?",
    why: "Nə üçün",
    select: "-i seçməlisiniz?",
    howItsWork: "Necə işləyir?",
    aboutUs: "Haqqımızda",
    faq: "FAQ",
    privacyPolicy: "Privacy policy",
    mail: "info@bid.az",
    copyright: "Copyright",
    allterm: "bid. Bütün hüquqlar qorunur.",
    howItsWorksDesc: "Proqramın işləməsinin 3 mərhələdə izahı",
    howItsWorksSteps: [
      "Qeydiyyatdan keçin",
      "Profil yaradın",
      "Sifariş təkliflərini görün",
    ],
    ownerReqistration: "Sahibkar qeydiyyatı",
    owner: "Sahibkar",
    driverReqistration: "Sürücü qeydiyyatı",
    taxiCertificateReq: "Buraxılış vəsiqəsi qeyd edilməlidir.",
    taxiCertif: "Buraxılış vəsiqəsi *məcburi",
    taxiCardReq: "Buraxılış kartı qeyd edilməlidir.",
    taxiCard: "Buraxılış kartı *məcburi",
    email: "E-poçt",
    correctMobileNum: "Nömrə düzgün deyil",
    mobileNum: "Telefon *məcburi",
    genderReq: "Cinsiyyət seçilməlidir",
    gender: "Cins *məcburi",
    genderMale: "Kişi",
    genderFemale: "Qadın",
    yearLimits: "Aşağı yaş həddi 15 və yuxarı yaş həddi 91 olmalıdır",
    dataReq: "Tarix qeyd olunmalıdır",
    dataReq2: "Doğum tarixi *məcburi",
    surnameLimits: "Soyad ən az 2 və ən çox 20 simvol ola bilər",
    surnameReq: "Soyad *məcburi",
    nameLimits: "Ad ən az 2 və ən çox 20 simvol ola bilər",
    nameReq: "Ad *məcburi",
    welcome: "Xoş Gəlmişsiniz!",
    autoCounts: "Avtoparkdaki avtomobillərin sayı *məcburi",
    autoCountsReq: "Avtomobil sayı qeyd edin",
    selectYourTaxi: "Taksinizi özünüz seçin",
    optimalTarif: "Hər yerdə sərfəli tarif",
    fromPrices: "dan başlayan qiymətlərlə",
    thinkYourBudget:
      "Biz maksimum dərəcədə sizin büdcənizi və rahatlığınızı düşünürük",
    travelWithOptimal: "Ən optimal şərtlərlə səyahət edin!",
    travelWith2:
      "Göndərilən təklifləri dəyərləndirərək, sizin üçün ən optimal şəraitlərdə səyahət edin!",
    withoutCommisions: "Komissiyasız!",
    centsFromBalance: "Hər gediş üçün balansdan 0.30 AZN çıxılacaq.",
    infoAboutTr: "Gediş haqqında ətraflı məlumat əldə edin!",
    infoAboutTr2:
      "Gediş başlamadan öncə başlanğıc və təyinat nöqtəsi barədə məlumatlanma imkanı!",
  },
  ru: {
    about: "О нас",
    aboutDesc1: "Мы привносим профессионализм во всё, что делаем.",
    aboutDesc2:
      '"Bid Taxi" — это мобильное приложение, созданное Азербайджанскими специалистами. Авторские права на программу зарегистрированы в Агентстве интеллектуальной собственности Азербайджанской Республики и защищены соответствующим законодательством. Все права, связанные с авторством, принадлежат ООО "Bid Taxi". Основная цель проекта — организация такси-сервиса через мобильное приложение. Стартап-сертификат был выдан Агентством развития малого и среднего бизнеса Азербайджанской Республики, а разрешение на деятельность оператора такси от AYDA было получено 31.05.2024 года (ТСО № 10012). Приложение "Bid Taxi" позволяет водителям и пассажирам быстро связываться друг с другом, принимать заказы и обеспечивать услуги такси на выгодных условиях для обеих сторон. Наша цель — сделать комфортные услуги такси доступными каждому по справедливой цене.',
    autoTypesName: "Наши транспортные средства",
    autoTypes: ["Эконом", "Бизнес", "XL"],
    rider: "Пассажир",
    requared: "- требуется!",
    driver: "Водитель",
    lowComisions: "Меньше комиссий,",
    moreReve: "Больше заработка!",
    forEveryRide: "За каждую поездку всего лишь",
    comision: "комиссия",
    payComision: "оплачивайте комиссию.",
    addiR: "получите до дополнительного ежемесячного дохода!",
    forAzn: "AZN",
    additionalReven: "до дополнительного дохода",
    bidDriver: "Bid: Водитель",
    bidRider: "Bid: Пассажир",
    faqQuestions: "Часто задаваемые вопросы",
    faqDesc1:
      "В этом разделе вы можете увидеть запросы, которые пользователи Bid отправляют регулярно.",
    downloadMOre: "Загрузить больше",
    whyBid: "Почему Bid?",
    why: "Почему",
    select: "должны выбрать?",
    howItsWork: "Как это работает?",
    aboutUs: "О нас",
    faq: "FAQ",
    privacyPolicy: "Политика конфиденциальности",
    mail: "info@bid.az",
    copyright: "Авторское право",
    allterm: "bid. Все права защищены.",
    howItsWorksDesc: "Описание работы программы в 3 этапах",
    howItsWorksSteps: [
      "Зарегистрируйтесь",
      "Создайте профиль",
      "Просматривайте предложения заказов",
    ],
    ownerReqistration: "Регистрация владельца",
    owner: "Владелец",
    driverReqistration: "Регистрация водителя",
    taxiCertificateReq: "Должен быть указан выпускной сертификат.",
    taxiCertif: "Выпускной сертификат *обязательно",
    taxiCardReq: "Должна быть указана выпускная карта.",
    taxiCard: "Выпускная карта *обязательно",
    email: "Электронная почта",
    correctMobileNum: "Номер неверный",
    mobileNum: "Телефон *обязательно",
    genderReq: "Необходимо выбрать пол",
    gender: "Пол *обязательно",
    genderMale: "Мужчина",
    genderFemale: "Женщина",
    yearLimits: "Минимальный возраст 15 лет, максимальный — 91 год",
    dataReq: "Необходимо указать дату",
    dataReq2: "Дата рождения *обязательно",
    surnameLimits: "Фамилия должна содержать от 2 до 20 символов",
    surnameReq: "Фамилия *обязательно",
    nameLimits: "Имя должно содержать от 2 до 20 символов",
    nameReq: "Имя *обязательно",
    welcome: "Добро пожаловать!",
    autoCounts: "Количество автомобилей в автопарке *обязательно",
    autoCountsReq: "Укажите количество автомобилей",
    selectYourTaxi: "Выберите своё такси",
    optimalTarif: "Выгодный тариф везде",
    fromPrices: "цены от",
    thinkYourBudget: "Мы максимально заботимся о вашем бюджете и комфорте",
    travelWithOptimal: "Путешествуйте на самых выгодных условиях!",
    travelWith2:
      "Оцените предложения и выберите самые оптимальные условия для поездки!",
    withoutCommisions: "Без комиссий!",
    centsFromBalance: "С каждого заказа будет списано 0,30 AZN с баланса.",
    infoAboutTr: "Получите подробную информацию о поездке!",
    infoAboutTr2:
      "Получите информацию о начальной и конечной точках поездки до её начала!",
  },
  en: {
    about: "About Us",
    aboutDesc1: "We bring professionalism to everything we do.",
    aboutDesc2:
      '"Bid Taxi" is a mobile application created by Azerbaijani specialists. The copyright of the program is registered with the Intellectual Property Agency of the Republic of Azerbaijan and protected by the relevant legislation. All rights related to authorship belong to "Bid Taxi" LLC. The main goal of the project is to organize a taxi service through a mobile application. A startup certificate was issued by the Small and Medium Business Development Agency of the Republic of Azerbaijan, and permission for taxi order operator activity was obtained from AYDA on May 31, 2024 (TSO No. 10012). The "Bid Taxi" app enables drivers and passengers to connect quickly, accept orders, and provide taxi services under mutually beneficial conditions. Our goal is to make comfortable taxi services accessible to everyone at a fair price.',
    autoTypesName: "Our Vehicles",
    autoTypes: ["Economy", "Business", "XL"],
    rider: "Passenger",
    requared: "- required!",
    driver: "Driver",
    lowComisions: "Lower commissions,",
    moreReve: "More earnings!",
    forEveryRide: "For each ride only",
    comision: "commission",
    payComision: "pay the commission.",
    addiR: "earn additional monthly income up to!",
    forAzn: "AZN",
    additionalReven: "up to additional income",
    bidDriver: "Bid: Driver",
    bidRider: "Bid: Passenger",
    faqQuestions: "Frequently Asked Questions",
    faqDesc1:
      "In this section, you can see the queries that Bid users send regularly.",
    downloadMOre: "Load more",
    whyBid: "Why Bid?",
    why: "Why should you",
    select: "choose?",
    howItsWork: "How it works?",
    aboutUs: "About Us",
    faq: "FAQ",
    privacyPolicy: "Privacy Policy",
    mail: "info@bid.az",
    copyright: "Copyright",
    allterm: "bid. All rights reserved.",
    howItsWorksDesc: "Explanation of the app's operation in 3 steps",
    howItsWorksSteps: ["Register", "Create a profile", "View order offers"],
    ownerReqistration: "Owner Registration",
    owner: "Owner",
    driverReqistration: "Driver Registration",
    taxiCertificateReq: "The release certificate must be specified.",
    taxiCertif: "Release certificate *required",
    taxiCardReq: "The release card must be specified.",
    taxiCard: "Release card *required",
    email: "Email",
    correctMobileNum: "Number is incorrect",
    mobileNum: "Phone *required",
    genderReq: "Gender must be selected",
    gender: "Gender *required",
    genderMale: "Male",
    genderFemale: "Female",
    yearLimits: "Minimum age must be 15 and maximum age 91",
    dataReq: "Date must be specified",
    dataReq2: "Date of birth *required",
    surnameLimits: "Surname must be at least 2 and no more than 20 characters",
    surnameReq: "Surname *required",
    nameLimits: "Name must be at least 2 and no more than 20 characters",
    nameReq: "Name *required",
    welcome: "Welcome!",
    autoCounts: "Number of cars in the fleet *required",
    autoCountsReq: "Specify the number of cars",
    selectYourTaxi: "Choose your taxi",
    optimalTarif: "Affordable rates everywhere",
    fromPrices: "starting from",
    thinkYourBudget: "We are highly considerate of your budget and comfort",
    travelWithOptimal: "Travel with the best conditions!",
    travelWith2:
      "Evaluate the offers and travel with the most optimal conditions for you!",
    withoutCommisions: "Without commissions!",
    centsFromBalance:
      "0.30 AZN will be deducted from the balance for each trip.",
    infoAboutTr: "Get detailed information about the trip!",
    infoAboutTr2:
      "Get informed about the start and destination points before the trip begins!",
  },
};
