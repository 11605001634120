import React from "react";
import "../assets/styles/footer.css";
import { Link } from "react-scroll";
import bidPurple from "../assets/images/BiD-LOQO-purple.svg";
import phone from "../assets/images/phone.svg";
import location from "../assets/images/location.svg";
import mail from "../assets/images/mail.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { options } from "../constants";
import { useSelector } from "react-redux";

import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
function Footer() {
  const language = useSelector((state) => state.language);
  const facebook = (
    <FontAwesomeIcon className="footer-social-icon" icon={faFacebookF} />
  );
  const instagram = (
    <FontAwesomeIcon className="footer-social-icon" icon={faInstagram} />
  );
  const linkedin = (
    <FontAwesomeIcon className="footer-social-icon" icon={faLinkedinIn} />
  );

  return (
    <div className="Footer">
      <img className="bid-logo" src={bidPurple} alt="bid-logo" />
      <p className="footer-text">
        {options[language].rider}{" "}
        <img className="bid-logo-small" src={bidPurple} alt="" />{" "}
        {options[language].requared}
      </p>
      <div className="footer-links">
        <li>
          <Link to="why" spy={true} smooth={true}>
            {options[language].whyBid}
          </Link>
        </li>
        <li>
          <Link to="how" spy={true} smooth={true}>
            {options[language].howItsWork}
          </Link>
        </li>
        <li>
          <Link to="about" spy={true} smooth={true}>
            {options[language].aboutUs}
          </Link>
        </li>
        <li>
          <Link to="faq" spy={true} smooth={true}>
            {options[language].faq}
          </Link>
        </li>
        <li>
          <a
            href="/PrivacyPolicy.html"
            rel="external nofollow noopener"
            target="_blank"
            style={{
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {options[language].privacyPolicy}
          </a>
        </li>
      </div>
      <div className="footer-contact-list">
        <a href="mailto: info@bid.az" className="footer-email">
          <div className="footer-circle">
            <img src={mail} alt="mail-icon" />
          </div>
          {options[language].mail}
        </a>
        <a href="tel:+994555152128" className="footer-email">
          <div className="footer-circle">
            <img src={phone} alt="mail-icon" />
          </div>
          +994555152128
        </a>
       
      </div>

      <div className="footer-socials">
        <a
          href="https://www.facebook.com/Bid-TAXI-107556738608969/?ref=pages_you_manage"
          target="_blank"
          rel="noreferrer"
          className="footer-social-icon"
        >
          {facebook}
        </a>
        <a
          href="https://www.instagram.com/bid_taxi/"
          target="_blank"
          rel="noreferrer"
          className="footer-social-icon"
        >
          {instagram}
        </a>
        <a
          href="https://www.linkedin.com/company/81373059/admin/"
          target="_blank"
          rel="noreferrer"
          className="footer-social-icon"
        >
          {linkedin}
        </a>
      </div>
      <div className="horizontal-line"></div>
      <p className="copyright">
        {options[language].copyright} &copy; {new Date().getFullYear()}{" "}
        {options[language].allterm}
      </p>
    </div>
  );
}

export default Footer;
