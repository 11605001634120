import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import bidPurple from "../assets/images/BiD-LOQO-purple.svg";
import bidWhite from "../assets/images/bid-logo-wh.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import "../assets/styles/navbar.css";
import { useDispatch, useSelector } from "react-redux";
import { options } from "../constants";
import { setLanguage } from "../redux/action";
const languages = ["az", "en", "ru"];
const Navbar = ({ setLoggedOut, loggedOut, setIsOpen }) => {
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const facebook = (
    <FontAwesomeIcon className="mm-social-link-icon" icon={faFacebookF} />
  );
  const instagram = (
    <FontAwesomeIcon className="mm-social-link-icon" icon={faInstagram} />
  );
  const linkedin = (
    <FontAwesomeIcon className="mm-social-link-icon" icon={faLinkedinIn} />
  );

  const [menu, setMenu] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const changeNavBg = () => {
    if (window.scrollY >= 100) setNavbar(true);
    else setNavbar(false);
  };
  window.addEventListener("scroll", changeNavBg);
  const changeLanguage = (lang) => {
    if (lang !== language) {
      localStorage.setItem("language", lang);
      dispatch(setLanguage(lang));
      if (window.location.pathname === "/") {
        window.location.href = window.location.origin + "/" + lang;
      } else if (
        window.location.href?.includes("/az") ||
        window.location.href?.includes("/en") ||
        window.location.href?.includes("/ru")
      ) {
        window.location.href = window.location.href
          ?.replace("/az", "/" + lang)
          ?.replace("/ru", "/" + lang)
          ?.replace("/en", "/" + lang);
      } else {
        window.location.href =
          window.location.origin + "/" + lang + window.location.pathname;
      }
    }
  };
  return (
    <>
      <div className={navbar ? "navbar nav-fixed" : " navbar "}>
        <div className="nav-container">
          <NavLink to="/">
            <img
              className="logo"
              src={navbar ? bidPurple : bidWhite}
              alt="bid-logo"
            />
          </NavLink>
          <div className={"nav-links " + (menu && "active ")}>
            <ul className="nav-links-list">
              <li
                className={
                  navbar
                    ? "fixed link effect-underline"
                    : "link effect-underline"
                }
              >
                <Link
                  onClick={() => setMenu(false)}
                  to="why"
                  spy={true}
                  smooth={true}
                >
                  {options[language].whyBid}
                </Link>
              </li>
              <li
                className={
                  navbar
                    ? "fixed link effect-underline"
                    : "link effect-underline"
                }
              >
                <Link
                  onClick={() => setMenu(false)}
                  to="how"
                  spy={true}
                  smooth={true}
                >
                  {options[language].howItsWork}
                </Link>
              </li>

              <li
                className={
                  navbar
                    ? "fixed link effect-underline"
                    : "link effect-underline"
                }
              >
                <Link
                  onClick={() => setMenu(false)}
                  to="about"
                  spy={true}
                  smooth={true}
                >
                  {options[language].aboutUs}
                </Link>
              </li>

              <li
                className={
                  navbar
                    ? "fixed link effect-underline"
                    : "link effect-underline"
                }
              >
                <Link
                  to="faq"
                  spy={true}
                  smooth={true}
                  onClick={() => setMenu(false)}
                >
                  {options[language].faq}
                </Link>
              </li>
            </ul>

            <div className="nav-btns">
              <div className="register-buttons2">
                <NavLink
                  to="/register-fleet"
                  className={navbar ? "signup fill" : "signup"}
                >
                  {options[language].ownerReqistration}
                </NavLink>
                <NavLink
                  to="/register"
                  className={navbar ? "signup fill" : "signup"}
                >
                  {options[language].driverReqistration}
                </NavLink>
              </div>

              <div id="dropdown">
                {languages
                  .sort((a, b) => {
                    if (a === language) return -1;
                    if (b === language) return 1;
                    return a.localeCompare(b);
                  })
                  .map((el, index) => {
                    return (
                      <button
                        className={navbar && "fill"}
                        onClick={() => changeLanguage(el)}
                      >
                        {el.toUpperCase()}
                      </button>
                    );
                  })}
              </div>

              {/* <div className="mobile-menu-social-links active">
                <a className="mm-social-link">{facebook}</a>
                <a className="mm-social-link">{instagram}</a>
                <a className="mm-social-link">{linkedin}</a>
              </div> */}
            </div>
          </div>

          <div
            onClick={() => setMenu(!menu)}
            className={"menu-btn " + (menu && "open")}
          >
            <div
              className={
                navbar ? "menu-btn__burger burger-color" : "menu-btn__burger"
              }
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
