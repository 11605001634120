import React from 'react'
import '../assets/styles/about.css'
import prius from '../assets/images/prius.png'
import sl from '../assets/images/biznes.png'
import xl from '../assets/images/xl.png'
import { options } from "../constants";
import { useSelector } from 'react-redux'

function About (props) {
  const language = useSelector(state => state.language)
  return (
    <div className='About' id='about'>
      <h2 className='about-heading'>{options[language].about}</h2>
      <p className='about-small-text'>
      {options[language].aboutDesc1}
      </p>
      <p className='about-content'>
      {options[language].aboutDesc2}
      </p>

      <div className='about-bg'>
        <div className='about-bg-container'>
          <h3 className='about-bg-heading'>{options[language].autoTypesName}</h3>
          <div className='about-cards'>
            <div className='about-card'>
              <img src={prius} alt='' />
              <h3 className='about-card-title'>{options[language].autoTypes[0]}</h3>
            </div>
            <div className='about-card'>
              <img src={sl} alt='' />
              <h3 className='about-card-title'>{options[language].autoTypes[1]}</h3>
            </div>
            <div className='about-card'>
              <img src={xl} alt='' />
              <h3 className='about-card-title'>{options[language].autoTypes[2]}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
