import React from "react";
import "../assets/styles/bannerCards.css";
import driverImage from "../assets/images/driverImage.png";
import driverAppIcon from "../assets/images/driverAppIcon.svg";
import googlePlayIcon from "../assets/images/googlePlayIcon.svg";
import AppstoreIcon from "../assets/images/AppstoreIcon.svg";
import { options } from "../constants";
import { useSelector } from "react-redux";
const DriverCard = () => {
  const language = useSelector(state => state.language)
  return (
    <div className="driver-card-part">
      <div className="card-banner">
        <div
          className="left"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            width: "100%",  
            padding: "10px",
          }}
        >
          <span className="span-card-banner-1">{options[language].driver}</span>
          <span
            className="span-card-banner-2"
            style={{
              marginTop: "20px",
            }}
          >
           {options[language].lowComisions}
          </span>
          <span className="span-card-banner-2">{options[language].moreReve}</span>
          <span
            className="span-card-banner-3"
            style={{
              marginTop: "10px",
            }}
          >
            {options[language].forEveryRide}
          </span>
          <span className="span-card-banner-3">
            <span className="span-card-banner-5">
              0.30 <span className="span-card-banner-4 small-4-n">AZN</span>
            </span>{" "}
            {options[language].comision}
          </span>
          <span
            className="span-card-banner-5"
            style={{
              marginTop: "8px",
            }}
          >
            + 1500 <span className="span-card-banner-4 small-4">{options[language].forAzn}</span>
          </span>
          <span className="span-card-banner-3">{options[language].additionalReven}</span>
        </div>
        <div className="right">
          <img src={driverImage} alt="driver-image" />
        </div>
      </div>
      <div className="type-info">
        <img src={driverAppIcon} alt="driver app icon" />
        <span>{options[language].bidDriver}</span>
      </div>
      <div className="type-urls">
        <a
          href="https://play.google.com/store/apps/details?id=com.bidriver"
          target="_blank"
        >
          <img src={googlePlayIcon} alt="google play icon" />
        </a>
        <a
          href="https://apps.apple.com/az/app/bid-driver/id6497953366"
          target="_blank"
        >
          <img src={AppstoreIcon} alt="app store icon" />
        </a>
      </div>
    </div>
  );
};

export default DriverCard;
