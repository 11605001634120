import React, { useState } from "react";
import Navbar from "./Navbar";
import "../assets/styles/banner.css";
import bidLogoOnText from "../assets/images/bidLogoOnText.svg";
import UserCard from "./UserCard";
import DriverCard from "./DriverCard";
import { options } from "../constants";
import { useSelector } from "react-redux";

function BannerMobile({ btnRef }) {
  const language = useSelector(state => state.language)
  const [selecteType, setSelectedType] = useState(0);
  return (
    <>
      <div className="banner-content">
        <h1 className="header-banner">
          {options[language].rider}{" "}
          <img
            className="header-banner-img"
            src={bidLogoOnText}
            alt="bid logo"
          />{" "}
          {options[language].requared}
        </h1>
        <div
          style={{
            marginTop: "20px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div className="navigation-driver-user">
            <div
              onClick={() => setSelectedType(0)}
              className={`navigation-driver-user-element ${
                selecteType === 0 ? "active" : ""
              }`}
            >
              {options[language].driver}
            </div>
            <div
              onClick={() => setSelectedType(1)}
              className={`navigation-driver-user-element ${
                selecteType === 1 ? "active" : ""
              }`}
            >
              {options[language].rider}
            </div>
          </div>
          <div className="selected-type-cards">
            {selecteType === 0 ? <DriverCard /> : <UserCard />}
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerMobile;
