import "./App.css";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter as Router } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import RegisterFleet from "./pages/RegisterFleet";
import { useEffect } from "react";
import { setLanguage } from "./redux/action";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.location.href?.includes("/az", "/en", "/ru")) {
      if (window.location.href?.includes("/az")) {
        dispatch(setLanguage("az"));
        localStorage.setItem("language", "az");
      } else if (window.location.href?.includes("/ru")) {
        dispatch(setLanguage("ru"));
        localStorage.setItem("language", "ru");
      } else if (window.location.href?.includes("/en")) {
        dispatch(setLanguage("en"));
        localStorage.setItem("language", "en");
      } else dispatch(setLanguage("az"));
    }
  }, [window.location]);
  return (
    <Router>
      <>
        <ScrollToTop />
        <Routes>
          <Route path="/" index element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register-fleet" element={<RegisterFleet />} />

          <Route path="/az/" index element={<Home />} />
          <Route path="/az/register" element={<Register />} />
          <Route path="/az/register-fleet" element={<RegisterFleet />} />

          <Route path="/ru/" index element={<Home />} />
          <Route path="/ru/register" element={<Register />} />
          <Route path="/ru/register-fleet" element={<RegisterFleet />} />

          <Route path="/en/" index element={<Home />} />
          <Route path="/en/register" element={<Register />} />
          <Route path="/en/register-fleet" element={<RegisterFleet />} />
        </Routes>
      </>
    </Router>
  );
}

export default App;
