import React from "react";
import "../assets/styles/how.css";
import svg1 from "../assets/images/1.svg";
import svg2 from "../assets/images/2.svg";
import svg3 from "../assets/images/3.svg";
import { options } from "../constants";
import { useSelector } from "react-redux";

function How(props) {
  const language = useSelector((state) => state.language);
  return (
    <div className="How" id='how'>
      <h2 className="how-heading">
      {options[language].howItsWork}<span></span>
      </h2>
      <p className="how-small-text">{options[language].howItsWorksDesc}</p>
      <div className="how-cards-container">
        <div className="how-card">
          <span>01</span>
          <img src={svg1} alt="" className="how-card-img" />
          <p className="how-card-heading">{options[language].howItsWorksSteps[0]}</p>
        </div>
        <div className="how-card">
          <span>02</span>
          <img src={svg2} alt="" className="how-card-img" />
          <p className="how-card-heading">{options[language].howItsWorksSteps[1]}</p>
        </div>
        <div className="how-card">
          <span>03</span>
          <img src={svg3} alt="" className="how-card-img" />
          <p className="how-card-heading">{options[language].howItsWorksSteps[2]}</p>
        </div>
      </div>
    </div>
  );
}

export default How;
